import React from 'react';
import Name from '../FormComponents/Name';
import LocalNavbar from '../LocalNavbar';
import OptionsDropdown from '../FormComponents/OptionsDropdown';
import NotesInput from '../FormComponents/NotesInput';
import SubmitButton from '../FormComponents/SubmitButton';
import { apiUrl } from '../../Constants';
import { updateWineDetails } from '../../Helpers/apiHelpers';
import { Form } from 'react-bootstrap'
import { withRouter } from 'react-router-dom';
import PriceInput from '../FormComponents/PriceInput';
import Rating from '../FormComponents/Rating';

class WineDetailsForm extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			wine: '',
			type: '',
			list: '',
			isRatingChecked: false,
			notes: '',
			price: 0,
			listTypes: ['whitelist', 'blacklist']
		}
	}

	handleNameChange = (e) => {
		let name = e.target.value;
		this.setState({
			name: name
		});
	}

	handleTypeChange = (e) => {
		let type = e.target.value;
		this.setState({
			type: type
		});
	}

	handleListChange = (e) => {
		let list = e.target.value;
		this.setState({
			list: list
		});
	}

	handleNotesChange = (e) => {
		let notes = e.target.value;
		this.setState({
			notes: notes
		});
	}

	handlePriceChange = (e) => {
		let price = e.target.value;
		this.setState({
			price: price
		});
	}

	handleRatingChange = (e) => {
		let isRatingChecked = e.target.checked;
		console.log(isRatingChecked)
		this.setState({
			isRatingChecked: isRatingChecked
		});
	}

	handleEditWine = async (e) => {
		e.preventDefault();
		const { name, type, list, notes, isRatingChecked, price } = this.state;
		let originalName = this.props.wineDetails.name;
		const data = {
			"name": name,
			"type": type,
			"listName": list,
			"price": parseFloat(price),
			"notes": notes,
			"rating": isRatingChecked
		}

		const response = await updateWineDetails(originalName, data);

		if (response !== null) {
			this.props.history.push('/')
		}
		
	}

	componentDidMount() {
		const { wineDetails } = this.props;
		if (this.props) {
			this.setState({
				name: wineDetails.name,
				type: wineDetails.type,
				list: wineDetails.listName,
				notes: wineDetails.notes,
				price: wineDetails.price,
				isRatingChecked: wineDetails.rating
			});
		}
	}

	render() {
		const { name, type, list, isRatingChecked, notes, price, listTypes } = this.state;
		const { wineTypes } = this.props;

		return (
			<>
				<Form onSubmit={this.handleEditWine} >
					<Name
						name={name}
						handleChange={this.handleNameChange}
						title="Wine Name"
					/>
					<OptionsDropdown
						title="Type"
						optionsList={wineTypes}
						option={type}
						handleChange={this.handleTypeChange}
					/>
					<OptionsDropdown
						title="List"
						optionsList={listTypes}
						option={list}
						handleChange={this.handleListChange}
					/>
					<PriceInput
						price={price}
						handleChange={this.handlePriceChange}
					/>
					<NotesInput
						notes={notes}
						handleChange={this.handleNotesChange}
					/>
					<Rating
						isChecked={isRatingChecked}
						handleChange={this.handleRatingChange}
					/> 
					<SubmitButton buttonName="Edit Wine" />
				</Form>
			</>
		);
	}
}

export default withRouter(WineDetailsForm);