import { apiUrl } from '../Constants';

// Returns a list of all wine types in the databse
export async function getWineTypes() {
	try {
		const resp = await fetch(apiUrl + 'types', {
			method: 'GET'
		});
		const wineTypes = await resp.json();
		return wineTypes;
	} 
	catch (error) {
		console.log(error);
	}
}

// Returns the details for a specific wine
export async function getWineDetails(wine) {
	try {
		const resp = await fetch(apiUrl + wine, {
			method: 'GET'
		});
	
		const wineDetails = await resp.json();
	
		return wineDetails;
	}
	catch (error) {
		console.log(error);
	}
}

// Updates a given wine with the new details for a wine
export async function updateWineDetails(originalWine, data) {
	try {
		const resp = await fetch(apiUrl + originalWine, {
			method: 'PUT',
			body: JSON.stringify(data),
			headers: {
				"Content-Type": "application/json" // This is necessary for POST and PUT
			}
		});
		return resp.json();
	}
	catch (error) {
		console.log(error);
	}
}

// Creates a new wine in the database
export async function addNewWine(wineDetails) {
	try {
		const resp = await fetch(apiUrl, {
			method: 'POST',
			body: JSON.stringify(wineDetails),
			headers: {
				'Content-Type': 'application/json'
			}
		});

		return resp.json();
	}
	catch (error) {
		
	}
}