import React from 'react';
import LocalNavbar from '../LocalNavbar';
import { apiUrl } from '../../Constants';
import { Row, Container } from 'react-bootstrap';
import WineTable from '../WineTable';

const LayoutStyle = {
	'margin': '2.5%'
}

class WhiteListLayout extends React.Component{
	constructor(props) {
		super(props);

		this.state = {
			whiteList: [],
		};
	}

	// When the component mounts i.e. this page loads, this function will execute
	async componentDidMount() {
		// Fetch all whitelist wines from the database
		const response = await fetch(apiUrl + 'whitelist', {
			method: 'GET',
		});
		const whiteList = await response.json()

		// Stores the list in the state variabels whitelists
		// This function is async and will cause the page to re-render when completed
		this.setState({
			whiteList: whiteList
		});
	}

	render() {
		const { whiteList } = this.state;

		if (!whiteList) {
			return null;
		}

		return(
			<>	
				<LocalNavbar />
				<div style={LayoutStyle} >
					<Container>
						<Row>
							<WineTable tableName="White List" wineData={whiteList} />
						</Row>
					</Container>
				</div>
			</>
		);
	}
}

export default WhiteListLayout;