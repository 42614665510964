import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import '../../css/priceInput.css';

class PriceInput extends React.Component {
	render() {
		const { price, handleChange } = this.props;

		return (
			<Form.Group as={Row} controlId="price">
				<Col sm={5}>
					<Form.Label>Price</Form.Label>
					<div className="input-icon">
						<Form.Control type="number" value={price} onChange={handleChange} required />
						<i>$</i>
					</div>					
				</Col>
			</Form.Group>			
		);
	}
}

export default PriceInput;