import React from 'react';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap'
import { Link, withRouter } from 'react-router-dom';

function LocalNavbar() {
	return (
		<Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
			<Navbar.Brand href="/">
				My Wine Log
			</Navbar.Brand>
			<Navbar.Toggle aria-controls="responsive-navbar-nav" />
			<Navbar.Collapse id="responsive-navbar-nav">
				<Nav className="mr-auto">
					<Nav.Link href="/">Black List</Nav.Link>
					<Nav.Link href="/white-list">White List</Nav.Link>	
					<Nav.Link href="/add-wine">Add Wines</Nav.Link>
				</Nav>
			</Navbar.Collapse>
		</Navbar>
	);
}

export default withRouter(LocalNavbar);