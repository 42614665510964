import React from 'react';
import Name from '../FormComponents/Name';
import OptionsDropdown from '../FormComponents/OptionsDropdown';
import NotesInput from '../FormComponents/NotesInput';
import SubmitButton from '../FormComponents/SubmitButton';
import { apiUrl } from '../../Constants';
import { Form } from 'react-bootstrap'
import { withRouter } from 'react-router-dom';
import { addNewWine } from '../../Helpers/apiHelpers';
import Rating from '../FormComponents/Rating';
import PriceInput from '../FormComponents/PriceInput';

class AddWineForm extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			name: '',
			type: 'Pinot Noir',
			list: 'whitelist',
			isRatingChecked: false,
			notes: '',
			price: 0,
			listTypes: ['whitelist', 'blacklist']
		}
	}

	handleNameChange = (e) => {
		let name = e.target.value;
		this.setState({
			name: name
		});
	}

	handleTypeChange = (e) => {
		let type = e.target.value;
		this.setState({
			type: type
		});
	}

	handleListChange = (e) => {
		let list = e.target.value;
		this.setState({
			list: list
		});
	}

	handleNotesChange = (e) => {
		let notes = e.target.value;
		this.setState({
			notes: notes
		});
	}

	handlePriceChange = (e) => {
		let price = e.target.value;
		this.setState({
			price: price
		});
	}

	handleRatingChange = (e) => {
		let isRatingChecked = e.target.checked;
		console.log(isRatingChecked)
		this.setState({
			isRatingChecked: isRatingChecked
		});
	}

	handleAddWine = async (e) => {
		e.preventDefault();
		const { name, type, list, notes, price, isRatingChecked } = this.state;

		const data = {
			"name": name,
			"type": type,
			"listName": list,
			"price": parseFloat(price),
			"notes": notes,
			"rating": isRatingChecked
		}

		const response = await addNewWine(data);
		console.log(response)

		if (response !== null) {
			this.props.history.push('/')
		}
	}

	render() {
		const { name, type, list, isRatingChecked, price, notes, listTypes } = this.state;
		const { wineTypes } = this.props;
		if (!wineTypes) {
			return null;
		}
		return (
			<>
				<Form onSubmit={this.handleAddWine} >
					<Name
						name={name}
						handleChange={this.handleNameChange}
						title="Wine Name"
					/>
					<OptionsDropdown
						title="Type"
						optionsList={wineTypes}
						option={type}
						handleChange={this.handleTypeChange}
					/>
					<OptionsDropdown
						title="List"
						optionsList={listTypes}
						option={list}
						handleChange={this.handleListChange}
					/>
					<PriceInput
						price={price}
						handleChange={this.handlePriceChange}
					/>
					<NotesInput
						notes={notes}
						handleChange={this.handleNotesChange}
					/>
					<Rating
						isChecked={isRatingChecked}
						handleChange={this.handleRatingChange}
					/> 
					<SubmitButton buttonName="Add Wine" />
				</Form>
			</>
		);
	}
}

export default withRouter(AddWineForm);