import React from 'react';
import WineDetailsForm from './WineDetailsForm';
import LocalNavbar from '../LocalNavbar';
import { getWineTypes, getWineDetails } from '../../Helpers/apiHelpers';

const PageStyle = {
	padding: '2%'
}

class WineDetailsLayout extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			wineTypes: null,
			wineDetails: null
		}
	}

	async componentDidMount() {
		const { wine } = this.props.match.params;
		// Gets the list of distinct wine types
		const wineTypes = await getWineTypes();
		// Gets the details of the current wine
		const wineDetails = await getWineDetails(wine);

		this.setState({
			wineTypes: wineTypes,
			wineDetails: wineDetails
		});
	}

	render() {
		const { wineTypes, wineDetails } = this.state;
		const { wine } = this.props.match.params;
		
		if (!wineDetails) {
			return null;
		}

		return (
			<>
				<LocalNavbar />
				<div style={PageStyle} >
					<h1>Details for Wine: {wine}</h1>
					<WineDetailsForm wineTypes={wineTypes} wineDetails={wineDetails} />
				</div>
			</>
		);
	}
}

export default WineDetailsLayout;