import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';

class Name extends React.Component {
	render() {
		const { name, handleChange, title } = this.props;

		return (
			<Form.Group as={Row} controlId="name">
				<Col sm={5}>
					<Form.Label>{title}</Form.Label>
					<Form.Control type="name" value={name} onChange={handleChange} required />
				</Col>
			</Form.Group>			
		);
	}
}

export default Name;