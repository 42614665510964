import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import filterFactory, { selectFilter } from 'react-bootstrap-table2-filter';
import { columns, defaultSorted } from '../Constants';
import { Link } from 'react-router-dom';
import { getWineTypes } from '../Helpers/apiHelpers';

const { SearchBar } = Search;

class WineTable extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			wineTypes: null
		}
	}

	nameFormatter = (cell) => {
		return (
			<a>
				<Link to={{
					pathname: `/wines/${cell}`,
					state: {name: {cell}}
				}}>
				{cell}
				</Link>
			</a>
		);
	}

	priceFormatter = (cell) => {
		return (
			`$${cell}`
		);
	}

	ratingFormatter = (cell) => {
		if (cell === true) {
			console.log('starred')
			return (
				<div className="star-icon" style={{justifyContent: 'center'}} >
					<img src={require("../images/star.png")} alt="star" style={{height: '50px', width: '75px'}} />
				</div>
			);
		}
		else {
			return (null)
		}
	}

	async componentDidMount() {
		this.setState({
			wineTypes: await getWineTypes()
		});
	}

	render() {
		const { wineData, tableName } = this.props;
		const { wineTypes } = this.state;

		// Wait for the component to get the wine types to use for the filter
		if (!wineTypes) {
			return null;
		}

		// Dynamically create a JS Object with the values of the winetypes array as the key and value for any given key
		const wineTypeFilterOptions = wineTypes.reduce((o, key) => ({ ...o, [key]: key}), {});
		
		const ratingFilterOptions = {
			true: "Starred"
		}

		const columns = [
		{
			dataField: 'name',
			text: 'Name',
			sort: true,
			formatter: this.nameFormatter,
			style: {wordWrap: 'break-word'}
		}, 
		{
			dataField: 'type',
			text: 'Type',
			sort: true,
			style: {wordWrap: 'break-word'},
			filter: selectFilter({
				options: wineTypeFilterOptions
			})
		},
		{
			dataField: 'rating',
			text: 'Rating',
			sort: true,
			formatter: this.ratingFormatter,
			headerStyle: (column, colIndex) => {
				return { width: '6em', textAlign: 'center' };
			},
			filter: selectFilter({
				options: ratingFilterOptions
			})
		},
		];

		const defaultSorted = [{
			dataField: 'name',
			order: 'asc' // desc or asc
		}];

		return(
			<div>
				<ToolkitProvider
					keyField="name"
					data={ wineData }
					columns={ columns }
					search
				>
					{
						props => (
							<div>
								<h3>{tableName}</h3>
								<SearchBar { ...props.searchProps } />
								<BootstrapTable
									{ ...props.baseProps }
									filter={ filterFactory() }
									defaultSorted={defaultSorted}									
								/>
							</div>
						)
					}
				</ToolkitProvider>
			</div >
		);
	}
}

export default WineTable;
