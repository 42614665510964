import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';

class NotesInput extends React.Component {
	render() {
		const { notes, handleChange } = this.props;

		return (
			<Form.Group as={Row} controlId="notes">
				<Col sm={5}>
					<Form.Label>Wine Notes</Form.Label>
					<Form.Control as="textarea" value={notes} onChange={handleChange}/>
				</Col>
			</Form.Group>	
		);
	}
}

export default NotesInput;