import React from 'react';
import { Form, Row, Col, Button, Modal, } from 'react-bootstrap';
import Name from './Name';


class OptionsDropdown extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			// When true, modal will be displayed
			shouldShow: false,
			optionsList: this.props.optionsList,
			newWineType: ''
		}
	}

	// Functions that determine whether to show the modal or not
	handleShow = () =>this.setState({shouldShow: true})

	handleClose = () =>this.setState({shouldShow: false})

	handleNewWineTypeChange = (e) => {
		let newWineType = e.target.value;
		this.setState({
			newWineType: newWineType
		});
	}

	handleAddNewWineType = (e) => {
		e.preventDefault();
		this.setState(prevState => ({
			optionsList: [...prevState.optionsList, this.state.newWineType],
			shouldShow: false
		}));		
	}

	render() {
		const { option, handleChange, title } = this.props;
		const { shouldShow, optionsList } = this.state;
		let OptionsElem = null;
		// Only try to create option elements when the prop exists ( nullcheck)
		if (optionsList) {
			OptionsElem = optionsList.map((option) => {
				return (<option>{option}</option>);
			});
		}

		return (
			<Form.Group as={Row} style={{display: 'flex', alignItems: 'center'}}>
				<Col sm={5}>
					<Form.Label>{title}</Form.Label>
					<Form.Control as="select" value={option} onChange={handleChange} required >
						{OptionsElem}						
					</Form.Control>					
				</Col>
				{/* Only add the option to add a wine when the select input field is the wine type */}
				{title === "Type"
					 ?
						<Col sm={2} style={{marginTop: "2.5%"}} >
							<Button variant="info" size="sm" onClick={this.handleShow} >Add New Wine Type</Button>
							<Modal show={shouldShow} onHide={this.handleClose}>
								<Modal.Header closeButton>
								<Modal.Title>New Wine Type</Modal.Title>
								</Modal.Header>
								<Modal.Body>Add your new wine type below!</Modal.Body>
								<div style={{margin: '15px'}}>
									<Form>
										<Name title="Wine Type" ref={this.wineType} handleChange={this.handleNewWineTypeChange} />
									</Form>
								</div>								
								<Modal.Footer>
								<Button variant="secondary" onClick={this.handleClose}>
									Close
								</Button>
								<Button variant="primary" onClick={this.handleAddNewWineType}>
									Save Changes
								</Button>
								</Modal.Footer>
							</Modal>
						</Col>
					: null
				}
				
			</Form.Group>
		);
	}
}

export default OptionsDropdown;