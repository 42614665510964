import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';

class Rating extends React.Component {
	render() {
		const { isChecked, handleChange } = this.props;

		return (
			<Form.Group as={Row} controlId="name">
				<Col sm={5}>
					<Form.Group controlId="ratingCheckBox">
						<Form.Check type="checkbox" label="Excellent?" checked={isChecked} onClick={handleChange} />
					</Form.Group>
				</Col>
			</Form.Group>			
		);
	}
}

export default Rating;

