import React from 'react';
import LocalNavbar from '../LocalNavbar';
import AddWineForm from './AddWineForm';
import { getWineTypes } from '../../Helpers/apiHelpers';

const PageStyle = {
	padding: '2%'
}

class AddWineLayout extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			wineTypes: null
		}
	}

	async componentDidMount() {
		const wineTypes = await getWineTypes();

		this.setState({
			wineTypes: wineTypes
		});
	}
	
	render() {
		return (
			<>
				<LocalNavbar />
				<div style={PageStyle} >
					<h1>Add a Wine</h1>
					<AddWineForm wineTypes={this.state.wineTypes} />
				</div>
			</>
		);
	}
}

export default AddWineLayout;