import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import BlackListLayout from './Components/BlackListPage/BlackListLayout';
import WhiteListLayout from './Components/WhiteListPage/WhiteListLayout';
import AddWineLayout from './Components//AddWinePage/AddWineLayout';
import WineDetailsLayout from './Components/WineDetailsPage/WineDetailsLayout';

function App() {
  return (
    <div className="App">
      <Router>
        <Route exact path="/" component={BlackListLayout} />
        <Route exact path="/white-list" component={WhiteListLayout} />
        <Route exact path="/add-wine" component={AddWineLayout} />
        <Route exact path="/wines/:wine" component={WineDetailsLayout} />
      </Router>
    </div>
  );
}

export default App;
