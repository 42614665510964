import React from 'react';
import { Button } from '../../../node_modules/react-bootstrap';

class SubmitButton extends React.Component {
	render() {
		return (
			<Button type="submit" variant="success" >{this.props.buttonName}</Button>
		);
	}
}

export default SubmitButton;